import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"

interface StyledTextAreaProps {
  $isOnDark: boolean
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  background: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.grey};

  color: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.offBlack
      : ({ theme }) => theme.colors.offBlack};

  border: none;
  resize: none;
  font-family: inherit;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 1rem;

  &::placeholder {
    color: inherit;
    font: inherit;
  }
`

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string
  isOnDark?: boolean
}

const TextArea: React.FC<TextAreaProps> = ({
  placeholder = "Vul hier uw tekst in",
  className,
  value,
  isOnDark = false,
  onChange,
}) => (
  <StyledTextArea
    rows={6}
    className={className}
    $isOnDark={isOnDark}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  ></StyledTextArea>
)

export default TextArea
