import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { SecondaryButton } from "../buttons"
import { GridContainer } from "../grid"
import { H2, H4 } from "../text"
import { Input } from "../form"

import YellowCircleShape from "../../svg/shape-circle-line.svg"
import QuarterLineShape from "../../svg/shape-quarter-line.svg"

import { toast } from "react-toastify"
import { Controller, useForm } from "react-hook-form"
import Rellax from "rellax"

const Container = styled(GridContainer)`
  grid-column: 1 / span 12;
  position: relative;
  padding: 4rem 1rem;
  background: ${({ theme }) => theme.colors.beukelaarGreen};
  color: ${({ theme }) => theme.colors.white};
  z-index: 999;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 8rem 0;
  }

  #tsparticles {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

const CircleContainer = styled.div`
  position: absolute;
  top: 4rem;
  left: 2rem;
  z-index: 1;

  svg {
    width: 4rem;
    height: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    left: 4rem;

    svg {
      width: 8rem;
      height: 8rem;
    }
  }
`

const QuarterDarkContainer = styled.div`
  position: absolute;
  top: 16rem;
  right: 0;
  z-index: 1;

  svg {
    width: 4rem;
    height: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    svg {
      width: 8rem;
      height: 8rem;
    }
`

const InnerContainer = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 4 / span 6;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    grid-column: 5 / span 4;
  }
`

const StyledForm = styled.form`
  display: grid;
  gap: 2rem;
  z-index: 100;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  width: max-content;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: fit-content;
    justify-self: center;
  }
`

const TopTitle = styled(H4)`
  display: none;
  color: ${({ theme }) => theme.colors.sand};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: unset;
  }
`

interface MakeAppointmentCTAProps {
  className?: string
}

interface FormData {
  name: string
  emailAddress: string
}

const MakeAppointmentCTA: React.FC<MakeAppointmentCTAProps> = ({
  className,
}) => {
  const { handleSubmit, control, reset, watch, formState } = useForm<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formsSubmitCount, setFormsSubmitCount] = useState<number>(0)

  const onSubmit = async (formData: FormData) => {
    const url = process.env.GATSBY_SEND_EMAIL_URL!

    setIsLoading(true)

    // Call the API to send an email using the Azure Function
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(formData),
    })

    setIsLoading(false)

    if (response.status === 200) {
      reset({ name: "", emailAddress: "" })
      toast.success("Dankjewel! Wij nemen zo snel mogelijk contact met je op")
    } else {
      toast.error("Helaas ging er iets mis.")
    }
  }

  useEffect(() => {
    const rellax = new Rellax(".rellax", {
      speed: 1.5,
      vertical: true,
      wrapper: "#make-appointment-cta",
      relativeToWrapper: true,
    })

    window.addEventListener(
      "scroll",
      () => {
        rellax.refresh()
      },
      { passive: true }
    )

    return () => {
      rellax.destroy()
    }
  }, [])

  useEffect(() => {
    const { errors, submitCount } = formState
    if (submitCount === formsSubmitCount) return
    setFormsSubmitCount(submitCount)

    if (errors.name && errors.name.type === "required") {
      toast.error("Vul alstublieft een geldige naam in")
      return
    }

    if (errors.emailAddress && errors.emailAddress.type === "required") {
      toast.error("Vul alstublieft een geldig e-mailadres in")
      return
    }
  }, [formState])

  return (
    <Container className={className} id="make-appointment-cta">
      <CircleContainer className="rellax" data-rellax-speed="-1.0">
        <YellowCircleShape />
      </CircleContainer>
      <QuarterDarkContainer className="rellax" data-rellax-speed="1.5">
        <QuarterLineShape />
      </QuarterDarkContainer>
      <InnerContainer>
        <TopTitle>
          Nieuwsgierigheid is een van de grote geheimen voor een gelukkig leven
        </TopTitle>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <H2>Nieuwsgierig wat wij voor jou kunnen betekenen?</H2>
          <Controller
            control={control}
            name="name"
            defaultValue={watch("name") ?? ""}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                isOnDark
                aria-invalid={formState.errors.name ? "true" : "false"}
                onChange={field.onChange}
                value={field.value}
                placeholder="Naam"
              />
            )}
          />
          <Controller
            control={control}
            name="emailAddress"
            defaultValue={watch("emailAddress") ?? ""}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                isOnDark
                onChange={field.onChange}
                value={field.value}
                aria-invalid={formState.errors.emailAddress ? "true" : "false"}
                placeholder="E-mailadres"
                type="email"
              />
            )}
          />
          <StyledSecondaryButton isOnDark disabled={isLoading} type="submit">
            {isLoading ? "Verzenden.." : "Kom in contact"}
          </StyledSecondaryButton>
        </StyledForm>
      </InnerContainer>
    </Container>
  )
}

export default MakeAppointmentCTA
