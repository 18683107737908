import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { H4 } from "./text"
import LinkedInSVG from "../svg/linkedin.svg"

const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.beukelaarGreenDark};
  color: ${({ theme }) => theme.colors.white};
`

const InnerContainer = styled.div`
  padding: 4rem 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }
`

const SectionsContainer = styled.div`
  display: grid;
  gap: 2rem;
  margin-bottom: 4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
`

const LinksContainer = styled.div`
  display: grid;
`

const SectionContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 25%;
    word-wrap: break-word;
  }
`

const SectionTitle = styled(H4)`
  margin-bottom: 1rem;
`

interface SectionProps {
  title: string
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <SectionContainer>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </SectionContainer>
)

const Footer: React.FC = ({}) => (
  <FooterContainer>
    <InnerContainer>
      <SectionsContainer>
        <Section title="Hoofdkantoor">
          <p>Appelsestraat 3</p>
          <p>3862 PG Appel (Nijkerk)</p>
        </Section>
        <Section title="Diensten">
          <LinksContainer>
            <Link to="/diensten/organisatieontwikkeling/">
              Organisatieontwikkeling
            </Link>
            <Link to="/diensten/teamontwikkeling/">Teamontwikkeling</Link>
            <Link to="/diensten/leiderschap/">Leiderschapsontwikkeling</Link>
            <Link to="/diensten/diversiteit-en-inclusie/">
              Diversiteit &amp; Inclusie
            </Link>
          </LinksContainer>
        </Section>
        <Section title="Contact">
          <p>Mieneke van der Wal</p>
          <p>info@debeukelaargroep.nl</p>
        </Section>
        <Section title="Socials">
          <a
            href="https://www.linkedin.com/company/de-beukelaar-groep/"
            target="_blank"
            aria-label="LinkedIn"
            rel="noreferrer"
          >
            <LinkedInSVG />
          </a>
        </Section>
      </SectionsContainer>
      <p>© {new Date().getFullYear()} De Beukelaar Groep</p>
    </InnerContainer>
  </FooterContainer>
)

export default Footer
