import React from "react"
import styled from "styled-components"

import { BaseContainer } from "../containers"

const Container = styled(BaseContainer)`
  grid-column: 1 / span 12;
  border-top: 2px solid ${({ theme }) => theme.colors.offBlack};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`
const LinksContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: end;
  }
`

interface NavigationDetailCTAProps {
  className?: string
}

const NavigationDetailCTA: React.FC<NavigationDetailCTAProps> = ({
  children,
  className,
}) => (
  <Container className={className}>
    <LinksContainer>{children}</LinksContainer>
  </Container>
)

export default NavigationDetailCTA
