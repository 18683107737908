import React from "react"

import styled from "styled-components"

import { SecondaryButton } from "../buttons"
import { NavigationLink } from "../links"

import FullLogoSVG from "../../svg/dbg-logo-full.svg"

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0 4rem 0;
  min-height: 48px;

  > * + * {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 6rem 0;

    > * + * {
      margin-top: 0;
    }
  }
`

const LogoContainer = styled.div`
  width: 192px;
  height: 100%;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
`

const LinksContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  order: 3;

  > * + * {
    margin-left: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    order: 2;
    justify-content: flex-end;
  }
`

const StyledAcademyLink = styled.a`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    order: 2;
  }
`

interface AcademyLinkProps {
  isOnDark?: boolean
}

const AcademyLink: React.FC<AcademyLinkProps> = ({ isOnDark = false }) => (
  <StyledAcademyLink
    role="button"
    href="https://academie.debeukelaargroep.nl/"
    target="_blank"
    rel="noreferrer"
  >
    <SecondaryButton isOnDark={isOnDark}>Online Academie</SecondaryButton>
  </StyledAcademyLink>
)

interface HeaderProps {
  isOnDark?: boolean
}

const Header: React.FC<HeaderProps> = ({ isOnDark = false }) => (
  <HeaderContainer>
    <LogoContainer>
      <NavigationLink ariaLabel="Homepage of De Beukelaar Groep" to="/">
        <LogoContainer>
          {isOnDark ? <FullLogoSVG /> : <FullLogoSVG />}
        </LogoContainer>
      </NavigationLink>
    </LogoContainer>
    <LinksContainer>
      <NavigationLink isOnDark={isOnDark} to="/over-ons/">
        Over ons
      </NavigationLink>
      <NavigationLink isOnDark={isOnDark} to="/diensten/">
        Diensten
      </NavigationLink>
      <NavigationLink isOnDark={isOnDark} to="/contact/">
        Contact
      </NavigationLink>
    </LinksContainer>
    <AcademyLink isOnDark={isOnDark} />
  </HeaderContainer>
)

export default Header
