import React from "react"
import styled from "styled-components"
import { SecondaryButton } from "../buttons"
import { BaseContainer } from "../containers"
import { H2 } from "../text"

import PlatformIllustration from "../../svg/platform-animated.svg"

const InnerContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    align-items: center;
  }
`

const IllustrationContainer = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  svg {
    max-width: 60%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0;

    svg {
      max-width: 80%;
    }
  }
`

const ContentContainer = styled.div``

interface OnlineAcademyCTAProps {
  className?: string
}

const Paragraph = styled.p`
  margin: 2rem 0;
`

const OnlineAcademyCTA: React.FC<OnlineAcademyCTAProps> = ({ className }) => (
  <BaseContainer className={className}>
    <InnerContainer>
      <IllustrationContainer>
        <PlatformIllustration />
      </IllustrationContainer>
      <ContentContainer>
        <H2>Bekijk onze online academie!</H2>
        <Paragraph>
          Leren en ontwikkelen op jouw tempo en wanneer jij wilt. Bekijk de
          gratis en betaalde cursussen over professionele groei, leiderschap en
          meer. Een coaching- of sparafspraak met een van onze mensen? Plan deze
          zelf in op de online academie!
        </Paragraph>
        <a
          role="button"
          href="https://academie.debeukelaargroep.nl/"
          target="_blank"
          rel="noreferrer"
        >
          <SecondaryButton>Online Academie</SecondaryButton>
        </a>
      </ContentContainer>
    </InnerContainer>
  </BaseContainer>
)

export default OnlineAcademyCTA
