import React, { ReactNode } from "react"
import styled, { css } from "styled-components"

import { Link as GatsbyLink } from "gatsby"

interface StyledBaseLinkStyleProps {
  $isOnDark: boolean
}

const BaseLinkStyle = css<StyledBaseLinkStyleProps>`
  color: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.offBlack};

  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;

  letter-spacing: 1px;

  &.active {
    color: ${props =>
      props.$isOnDark
        ? ({ theme }) => theme.colors.beukelaarGreenLight
        : ({ theme }) => theme.colors.beukelaarGreen};
  }
`

const InternalLink = styled(GatsbyLink)<StyledBaseLinkStyleProps>`
  ${BaseLinkStyle}
`

const ExternalLink = styled.a<StyledBaseLinkStyleProps>`
  ${BaseLinkStyle}
`

interface BaseLinkProps {
  children: ReactNode
  ariaLabel?: string
  to?: string
  href?: string
  className?: string
  isOnDark?: boolean
}

const BaseLink: React.FC<BaseLinkProps> = ({
  className,
  isOnDark = false,
  children,
  ariaLabel,
  to,
  href,
}) => {
  return to ? (
    <InternalLink
      to={to}
      aria-label={ariaLabel}
      partiallyActive
      activeClassName="active"
      className={className}
      $isOnDark={isOnDark}
    >
      {children}
    </InternalLink>
  ) : (
    <ExternalLink
      $isOnDark={isOnDark}
      href={href}
      aria-label={ariaLabel}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </ExternalLink>
  )
}

export default BaseLink
