import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"

interface StyledInputProps {
  $isOnDark: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  background: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.grey};

  color: ${props =>
    props.$isOnDark
      ? ({ theme }) => theme.colors.offBlack
      : ({ theme }) => theme.colors.offBlack};

  height: 64px;
  line-height: 64px;
  border: none;

  font-family: inherit;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 0 1rem;
  text-transform: uppercase;

  &::placeholder {
    color: inherit;
    font: inherit;
    text-transform: uppercase;
  }
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  isOnDark?: boolean
}

const Input: React.FC<InputProps> = ({
  placeholder = "Vul hier uw tekst in",
  className,
  isOnDark = false,
  onChange,
  value,
  type = "text",
}) => (
  <StyledInput
    className={className}
    $isOnDark={isOnDark}
    placeholder={placeholder}
    type={type}
    value={value}
    onChange={onChange}
  ></StyledInput>
)

export default Input
