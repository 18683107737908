import React from "react"

import ReactCookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"

import { BeukelaarTheme } from "../themes/beukelaar-theme"
import { H4 } from "./text"
import { PrimaryButton } from "./buttons"

const CookieConsent: React.FC = () => {
  const location = useLocation()

  const onAccept = () => {
    initializeAndTrack(location)
  }

  return (
    <ReactCookieConsent
      disableStyles={false}
      location="bottom"
      style={{
        display: "grid",
        background: BeukelaarTheme.colors.beukelaarGreenDark,
        color: BeukelaarTheme.colors.white,
        padding: "2rem",
        alignItems: "center",
        gap: "2rem",
      }}
      contentStyle={{ margin: "0" }}
      cookieName={process.env.GATSBY_GOOGLE_ANALYTICS_COOKIE_NAME}
      ButtonComponent={PrimaryButton}
      buttonText="Accepteren"
      onAccept={onAccept}
    >
      <H4>
        Door op accepteren te klikken gaat u akkoord met het opslaan van cookies
        op uw apparaat voor het verbeteren van websitenavigatie en het
        analyseren van websitegebruik.
      </H4>
    </ReactCookieConsent>
  )
}

export default CookieConsent
